import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import Button from "react-bootstrap/Button";
import "../../css/Follow.css";
import styles from "../../css/Enquiry.module.css";
import { useNavigate, useLocation } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "react-datepicker/dist/react-datepicker.css";
import { BsTrash3Fill, BsPencilFill } from "react-icons/bs";
import axiosConfig from "../../services/axiosConfig";
import { FaArrowLeft } from "react-icons/fa";

function FollowUp() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const addNavigateButton = () => {
    navigate("/followupManagement");
  };
  // const [name,setName] = useState('');
  const [companyname, setcompanyname] = useState("");
  const [segment, setsegment] = useState("");
  const [street, setStreet] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [StreetLine, setStreetLine] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [Source, setSource] = useState("");
  const [Category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [Name, setname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Productenquired, setProductenquired] = useState("");
  const [Equivalentmodel, setEquivalentmodel] = useState("");
  const [Qty, setQty] = useState("");
  const [Expectedprice, setExpectedprice] = useState("");
  const [Nextfollowup, setNextfollowup] = useState(null);
  const [checkboxValue1, setCheckboxValue1] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const location = useLocation();
  const { enquiryID } = location.state || {}; // Get enquiryID from the passed state

  const [OrderValue, setOrderValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [Description, setDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [enquiryProducts, setEnquiryProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [EnquiryID, setEnquiryID] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [Total, setTotal] = useState("");
  const [Notes, setNotes] = useState("");
  const [FollowNotes, setFollowNotes] = useState("");
  const [Assigned, setAssigned] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState(
    new Date().toISOString().split("T")[0]
  ); // Current date
  const [Chalanges, setChalanges] = useState("");
  const [Requirement, setRequirement] = useState("");
  const [stages, setStages] = useState("");
  const [winPossibilities, setWinPossibilities] = useState("");
  const [Competitor, setCompetitor] = useState("");
  const [Priority, setPriority] = useState("");
  const [active, setActive] = useState("Open");
  const [companyId, setCompanyId] = useState("");
  const [companyAddressId, setCompanyAddressId] = useState("");
  const [City, setCity] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [contacts, setContacts] = useState([
    { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
  ]);
  const [enquiryDetails, setEnquiryDetails] = useState({});

  const [Challenges, setChallenges] = useState("");
  const [Budget, setBudget] = useState("");
  const [Deadline, setDeadline] = useState("");
  const [Contactperson1, setContactperson1] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Feedback, setFeedback] = useState("");
  const [followUps, setFollowUps] = useState([]);
  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const [formData, setFormData] = useState({
    productName: "",
    productId: "", // Store the selected product ID
    modelNo: "",
    qty: 0,
    orderValue: 0,
    description: "",
    contactType: "", // added field
    escalate: false, // added checkbox field
    requirementType: "",
    stages: "",
    priority: "",
    competitor: "",
    winPossibilities: "",
    challenges: "",
    contactType: "",
    followUpStage: "",
    nextFollowupDate: "",
    escalate: false,
    FollowNotes: "",
    createdDate: new Date().toISOString().split("T")[0],
  });

  // Handle form data changes for all fields

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCompanies([]);
    } else {
      const filtered = companies.filter((company) =>
        company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  }, [searchTerm, companies]);

  // // Handle company selection
  // const handleCompanySelect = (company) => {
  //   setSearchTerm(company.company_name); // Set the search term to the selected company
  //   setFilteredCompanies([]); // Clear suggestions
  //   handleChangetypes({ target: { value: company.company_name } }); // Load the company details
  // };

  const handleChangeUser = (event) => {
    const selectedValue = event.target.value;
    setUser(selectedValue);

    // If "New" is selected, reset all fields
    if (selectedValue === "Ford") {
      setSearchTerm("");
      setSource("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setCity("");
      setDistrict("");
      setState("");
      setPincode("");
      setContacts([
        { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
      ]); // Reset contacts array
    }
  };
  // Hide suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#companySearch")) {
        setFilteredCompanies([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Open modal
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };

  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };

  // Handle form submission (Add button)
  const handleClose = () => {
    console.log("Form submitted with data:", formData);
    closeModal(); // Close the modal after submission
  };
  // const [companyId, setCompanyId] = useState(0);

  const handleDateChange = (date) => {
    setNextfollowup(date);
  };
  // const [checkboxValue1, setCheckboxValue1] = useState(false);
  // const [checkboxValue2, setCheckboxValue2] = useState(false);
  // const [inputdata, setInputdata] = useState({
  //   quantity:"",
  // })

  const handleDelete = (enquiryID) => {
    // Your delete functionality here
    console.log(`Delete enquiry with ID: ${enquiryID}`);
  };

  const handleEdit = (enquiryID) => {
    // Your edit functionality here
    console.log(`Edit enquiry with ID: ${enquiryID}`);
  };
  const [createFileSave, setcreatefileSave] = useState(false);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function company() {
    let companyFile = {
      company_name: companyname,
      segment: segment,
      territory: AreaTerritory,
      company_location: "abc",
      category: type,
      type: user,
    };
    console.log("companyFile:", companyFile);
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, // handle checkbox specifically
    }));
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
    ]);
  };

  const cancelLastContact = () => {
    if (contacts.length > 1) {
      setContacts(contacts.slice(0, -1));
    }
  };

  let enquiryId = localStorage.getItem("enquiryId");

  useEffect(() => {
    // Check if the action is 'edit' in localStorage
    const action = localStorage.getItem("action");
    if (action === "edit") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }

    const storedCompanies = localStorage.getItem("companies");
    if (storedCompanies) {
      const parsedCompanies = JSON.parse(storedCompanies);
      setCompanies(parsedCompanies);
    } else {
      console.warn("No company data found in localStorage.");
    }
  }, []);

  const handleProductEdit = (productId) => {
    const productData = enquiryProducts.find(
      (product) => product.enquiry_product_id === productId
    );

    if (productData) {
      setFormData({
        product: productData.product_name,
        productId: productData.product_id, // Include product_id for editing
        modelNo: productData.model_no,
        qty: productData.quantity,
        orderValue: productData.order_value,
        description: productData.description,
      });
      setSelectedProduct(productId); // Set the selected product ID
      setIsEditing(true); // Enable edit mode
      setIsModalOpen(true); // Open modal
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const getCompanyDetail = localStorage.getItem("companyDetails");
  // console.log("rajjjjj ", getCompanyDetail);
  const parsedCompanyDetail = JSON.parse(getCompanyDetail);
  // console.log("parsedCompanyDetail::", JSON.parse(getCompanyDetail));

  const handleAddProduct = () => {
    const updatedProduct = {
      product_name: formData.productName, // Updated product name
      product_id: formData.productId, // Ensure product_id is updated
      model_no: formData.modelNo,
      quantity: formData.qty,
      order_value: formData.orderValue,
      description: formData.description,
      // enquiry_product_id: selectedProduct || new Date().getTime(), // Unique ID for new product
    };

    if (isEditing) {
      // Update existing product in the array
      setEnquiryProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.enquiry_product_id === selectedProduct
            ? updatedProduct
            : product
        )
      );
    } else {
      // Add new product to the array
      setEnquiryProducts((prevProducts) => [...prevProducts, updatedProduct]);
    }

    // Clear form and reset state
    setFormData({
      product: "",
      productId: "", // Clear product ID field
      modelNo: "",
      qty: 0,
      orderValue: 0,
      description: "",
    });
    setIsEditing(false);
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  // Fetch follow-ups
  const fetchFollowUps = async () => {
    try {
      const response = await axiosConfig.get(
        `/follow-ups/by-enquiry/${enquiryID}`
      );
      console.log("Follow-ups:", response.data);
      setFollowUps(response.data);
      setIsAddDisabled(response.data.length > 0);
    } catch (error) {
      console.error("Error fetching follow-up data:", error);
    }
  };
  useEffect(() => {
    console.log("useEffect called.");

    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      // Fetch enquiry details
      axiosConfig
        .get(`/enquiries/${enquiryID}`)
        .then((response) => {
          const data = response.data;
          setEnquiryDetails(data); // Store the response data if needed
          setEnquiryProducts(data.products || []);

          // Populate other fields from the response
          if (data) {
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setname(data.masterCompany.contacts[0].name || "");
            setEmailID(data.masterCompany.contacts[0].email || "");
            setContactNumber(data.contact || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");

            // Address details
            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_line_1 || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setSource(address.source || "");
            }

            // Contact details
            const contact = data.masterCompany?.contacts[0];

            if (contact) {
              setContacts([
                {
                  Name: contact.name || "",
                  Designation: contact.designation || "",
                  EmailID: contact.email || "",
                  ContactNumber: contact.phone || "",
                  Department: contact.department || "",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching enquiry details:", error);
        });

      fetchFollowUps();
    } else {
      console.error("Enquiry ID is not provided.");
    }
  }, [enquiryID]);

  const handleFollowUpDelete = async (followUpId) => {
    // Confirm deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this follow-up?"
    );
    if (!isConfirmed) return;

    try {
      // Send DELETE request to the API
      const response = await axiosConfig.delete(`/follow-ups/${followUpId}`);
      console.log("Follow-up deleted successfully:", response.data);

      // Refresh the list of follow-ups after deletion
      fetchFollowUps();
      setIsAddDisabled(response.data.length > 0);
    } catch (error) {
      console.error("Error deleting follow-up:", error);
    }
  };

  const handleFollowUpEdit = async (followUp) => {
    try {
      // Fetch the follow-up details by follow_up_id
      const response = await axiosConfig.get(
        `/follow-ups/${followUp.follow_up_id}`
      );
      console.log("Follow-up Details:", response.data);

      // Set the data into formData
      setFormData({
        ...formData,
        follow_up_id: response.data.follow_up_id, // Set the follow-up ID
        contactType: response.data.contact_type,
        followUpStage: response.data.follow_up_stage,
        nextFollowupDate: response.data.nextFollowDate,
        notes: response.data.notes || "",
        createdDate: response.data.created_date,
      });

      // Set editing state to true
      setIsEditing(true); // Ensure you're in edit mode

      // Open the modal
      setIsModalOpen(true);
      setIsAddDisabled(false);
    } catch (error) {
      console.error("Error fetching follow-up details:", error);
    }
  };

  const handleAddFollowup = async () => {
    try {
      if (
        !formData.contactType ||
        !formData.followUpStage ||
        !formData.nextFollowupDate
      ) {
        alert("Please fill in all required fields before submitting.");
        return;
      }

      const postData = {
        enquiry: { enquiry_id: enquiryID },
        created_date: formData.createdDate,
        status: "Pending",
        contact_type: formData.contactType,
        follow_up_stage: formData.followUpStage,
        nextFollowDate: formData.nextFollowupDate,
        escalate: formData.escalate,
        notes: formData.notes,
      };

      if (isEditing) {
        const updateResponse = await axiosConfig.put(
          `/follow-ups/${formData.follow_up_id}`,
          postData
        );
        console.log("Follow-up updated successfully:", updateResponse.data);
      } else {
        const postResponse = await axiosConfig.post("/follow-ups", postData);
        console.log("Follow-up added successfully:", postResponse.data);

        // Disable Add button after adding a follow-up
        setIsAddDisabled(true);
        console.log("Add Follow-up button disabled"); // Debugging log
      }

      setIsModalOpen(false);
      setIsEditing(false);
      setFormData({
        contactType: "",
        followUpStage: "",
        nextFollowupDate: "",
        escalate: false,
        notes: "",
        createdDate: new Date().toISOString().split("T")[0],
      });

      fetchFollowUps();
    } catch (error) {
      console.error("Error submitting follow-up:", error);
    }
  };

  return (
    <div>
      <Header />
      <form>
        <div className={styles["button-container"]}>
          <button
            className={styles["button-backArrow"]} // Apply styling to the button
            onClick={() => navigate("/FollowUpManagement")} // Navigate on click
          >
            <div className={styles["circle-container"]}>
              <FaArrowLeft size={14} /> {/* Render the back arrow icon */}
            </div>
            <span className={styles["back-text"]}>Back</span>{" "}
            {/* Add text after the icon */}
          </button>
        </div>
        <h2 className={styles["basic-info-title"]}>History</h2>
        <div className={styles["product-container"]}>
          <div className={styles["table-container"]}>
            <table className={styles["product-table"]}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Type of Contact</th>
                  <th>Follow-up Stage</th>
                  <th>Follow-up Date</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {followUps.map((followUp, index) => (
                  <tr key={followUp.follow_up_id}>
                    <td>{index + 1}</td>
                    <td>{followUp.created_date}</td>
                    <td>{followUp.contact_type}</td>
                    <td>{followUp.follow_up_stage}</td>
                    <td>{followUp.nextFollowDate}</td>
                    <td>{followUp.notes || "N/A"}</td>
                    <td>
                      {/* <BsTrash3Fill
                      className={styles['trash-icon']}
                      onClick={() => handleFollowUpDelete(followUp.follow_up_id)}
                    /> */}
                      <BsPencilFill
                        className={styles["edit-icon"]}
                        onClick={() => handleFollowUpEdit(followUp)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {!isAddDisabled && (
            <button
              type="button"
              onClick={openModal}
              className={styles["add-product-button"]}
            >
              Add Follow-up
            </button>
          )}
        </div>
      </form>
      <form>
        <h2 className={styles["basic-info-title"]}> Enquiry Details</h2>

        <div className={styles["basic-info-container"]}>
          {/* Row 1 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Enquiry ID</label>
            <input
              type="text"
              value={enquiryID || ""}
              readOnly
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Date</label>
            <input
              type="date"
              value={enquiryDetails?.date || ""} // Use optional chaining to prevent accessing 'date' on null
              readOnly // Make the field read-only
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Assigned To</label>
            <input
              type="text"
              value={Assigned || ""}
              onChange={(e) => setAssigned(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          {/* Row 2 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>
              Company Name<span className={styles["required"]}>*</span>
            </label>
            <input
              type="text"
              value={companyname || ""}
              onChange={(e) => setcompanyname(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Address</label>
            <input
              type="text"
              value={streetname}
              onChange={(e) => setDoorNo(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}> Category</label>
            <input
              type="text"
              value={Category || ""}
              onChange={(e) => setCategory(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          {/* Row 3 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Contact Person</label>
            <input
              type="text"
              value={Name}
              onChange={(e) => {
                const updatedContacts = [...Name];
                updatedContacts[0].Name = e.target.value;
                setContacts(updatedContacts);
              }}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Mobile</label>
            <input
              type="text"
              value={ContactNumber || ""}
              onChange={(e) => setContactNumber(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Email ID</label>
            <input
              type="text"
              value={EmailID || ""}
              onChange={(e) => setEmailID(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          {/* Row 4 - Pincode */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Notes</label>
            <textarea
              id="notes"
              value={Notes}
              // onChange={(e) =>
              //   setFormData((prev) => ({ ...prev, notes: e.target.value }))
              // }
              className={styles["enquiry-area"]}
            />
          </div>
        </div>
      </form>
      <form>
        <h2 className={styles["basic-info-title"]}> Classification</h2>

        <div className={styles["basic-info-container"]}>
          {/* Row 1 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>
              Requirement Type
            </label>
            <input
              type="text"
              value={Requirement}
              onChange={(e) => setRequirement(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Stages</label>
            <input
              type="text"
              value={stages}
              onChange={(e) => setStages(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Priority</label>
            <input
              type="text"
              value={Priority}
              onChange={(e) => setPriority(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          {/* Row 2 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Competitor</label>
            <input
              type="text"
              value={Competitor}
              onChange={(e) => setCompetitor(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>
              Win Possibility
            </label>
            <input
              type="text"
              value={winPossibilities}
              onChange={(e) => setWinPossibilities(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}> Challenges</label>
            <input
              type="text"
              value={Chalanges}
              onChange={(e) => setChalanges(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          {/* Row 4 - Pincode */}
          {/* <div className="follow-row">
          <label className="folloup-label">Notes</label>
          <textarea
            id="notes"
            value={Notes}
                    onChange={(e) => setNotes(e.target.value)}
            className="folloup-area"
          />
        </div> */}
        </div>
      </form>
      <form>
        <h2 className={styles["basic-info-title"]}>Product Details</h2>
        <div className={styles["product-container"]}>
          <div className={styles["table-container"]}>
            <table className={styles["product-table"]}>
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product Name</th>
                  <th>Model No</th>
                  <th>Qty</th>
                  <th>Order Value</th>
                  <th>Description</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {enquiryProducts.map((product, index) => (
                  <tr key={product.enquiry_product_id}>
                    <td>{index + 1}</td>
                    <td>{product.product_name}</td>
                    <td>{product.model_no}</td>
                    <td>{product.quantity}</td>
                    <td>{product.order_value}</td>
                    <td>{product.description}</td>
                    {/* <td>
                    <BsTrash3Fill
                      className={styles['trash-icon']}
                      onClick={() =>
                        handleProductDelete(product.enquiry_product_id)
                      }
                    />
                    <BsPencilFill
                      className={styles['edit-icon']}
                      onClick={() =>
                        handleProductEdit(product.enquiry_product_id)
                      }                    />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Total</label>
            <input
              type="text"
              value={Total}
              onChange={(e) => setTotal(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
          {/* <button
          type="button"
          onClick={openModalWithDelay}
          className="add-product-button"
        >
          Add Product
        </button> */}

          {isModalOpen && (
            <div className="follow-modal-overlay">
              <div className="follow-modal-container">
                <div className="follow-modal-header">
                  <h2 className="follow-modal-title">NextFollowup</h2>
                  <div className="follow-close-modal" onClick={handleClose}>
                    ×
                  </div>
                </div>
                <form>
                  <div className="form-group">
                    <label className="follow-form-label"> Created Date</label>
                    <input
                      type="text"
                      name="modelNo"
                      value={formData.createdDate}
                      className="follow-input-field"
                    />
                  </div>

                  <div className="form-group">
                    <label className="follow-form-label"> Contact Type</label>
                    <select
                      name="contactType"
                      value={formData.contactType}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          contactType: e.target.value,
                        })
                      }
                      className="follow-input-field"
                    >
                      <option value="">Select Contact Type</option>
                      <option value="mail">Mail</option>
                      <option value="phone_call">Phone Call</option>
                      <option value="personal_visit">Personal Visit</option>
                      <option value="whatsapp">WhatsApp</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="follow-form-label">
                      {" "}
                      Follow up stages
                    </label>
                    <select
                      name="followUpStage"
                      value={formData.followUpStage}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          followUpStage: e.target.value,
                        })
                      }
                      className="follow-input-field"
                    >
                      <option value="">Follow-up stages</option>
                      <option value="model_arrive">Model Arrive</option>
                      <option value="demo_1">Demo I</option>
                      <option value="quotation">Quotation</option>
                      <option value="demo_II">Demo II</option>
                      <option value="srpf">SPRF</option>
                      <option value="srpf_approve">
                        SPRF Approval Pending
                      </option>
                      <option value="RQF">Changes in RFQ</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="follow-form-label">Escalate</label>
                    <input
                      type="checkbox"
                      name="escalate"
                      checked={formData.escalate}
                      onChange={handleInputChange}
                      className="follow-input"
                    />
                  </div>

                  <div className="form-group">
                    <label className="follow-form-label">Next Follow-up</label>
                    <input
                      type="date"
                      name="nextFollowupDate"
                      value={formData.nextFollowupDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          nextFollowupDate: e.target.value,
                        })
                      }
                      className="follow-input-field"
                    />
                  </div>
                  <div className="form-group">
                    <label className="follow-form-label">Notes </label>
                    <textarea
                      name="notes"
                      value={formData.notes || ""}
                      onChange={(e) => {
                        setFormData({ ...formData, notes: e.target.value });
                        console.log("Updated Notes:", e.target.value); // Log for debugging
                      }}
                      className={styles["enquiry-area"]}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={handleAddFollowup}
                      className={styles["submit-button"]}
                    >
                      {isEditing ? "Update" : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </form>
      <form>
        <h2 className={styles["basic-info-title"]}> </h2>

        <div className={styles["basic-info-container"]}>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Date</label>
            <input
              type="date"
              value={enquiryDetails?.date || ""} // Use optional chaining to prevent accessing 'date' on null
              readOnly // Make the field read-only
              className={styles["basic-info-input"]}
            />
          </div>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}> Assigned To</label>
            <input
              type="text"
              value={Assigned}
              onChange={(e) => setAssigned(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
        </div>
      </form>
      <div className="buttons">
        {/* <Button
    className="primary-button"
    onClick={() => {
      setcreatefileSave(true);
      if (isEditMode) {
        saveEnquiry();
      } else {
        enquiry(companyId, companyAddressId);
      }
    }}
  >
    {isEditMode ? "Update" : "Submit"}
  </Button> */}
      </div>

      <Dialog
        open={createFileSave}
        onClose={() => setcreatefileSave(false)}
        PaperProps={{ className: "dialog-paper" }}
      >
        <DialogContent dividers className="dialog-content">
          <img
            src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
            alt="Success Icon"
            className="success-icon"
          />
          <span className="success-message">
            Enquiry ID: {enquiryId} Submitted Successfully...!!!
          </span>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            className="ok-button"
            onClick={addNavigateButton}
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* <div className="reset-button">
  <Button
    className="reset-btn"
    onClick={() => {
      // clearAllInputs();
    }}
  >
    Reset
  </Button>
</div> */}
    </div>
  );
}
export default FollowUp;
