import React, { useState, useEffect } from "react";
import "../../css/FollowupManagement.css";
import Header from "../../home/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import styles from "../../css/EnquiryManagement.module.css";
import DatePicker from "react-datepicker";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsCurrencyRupee,
  BsChevronDown,
  BsFilter,
} from "react-icons/bs";
import { MdBusiness, MdPerson } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaIdBadge, FaCalendarAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import axiosConfig from "../../services/axiosConfig";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Modal } from "react-bootstrap";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function FollowUpManagement() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const compName = localStorage.getItem("compName");
  const userId = localStorage.getItem("loggedInUserId"); 
  const [fromFollowDate, setFromDate] = useState("");
  const [toFollowDate, setToDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleClosePopup = () => setShowErrorPopup(false);
  const formatEnquiryData = (data) =>
    data.filter((item) => item.status === "Open")
      .map((item, index) => ({
        id: index + 1,
        enquiryID: item.enquiry_id || "N/A",
        Company_Name: item.masterCompany?.company_name || "Unknown Company",
        Name: item.masterCompany?.contacts[0]?.name || "N/A",
        Mobile_No: item.masterCompany?.contacts[0]?.phone || "N/A",
        Email_Id: item.email || "N/A",
        ExpectedPrice: item.total
          ? `₹${Number(item.total).toLocaleString()}`
          : "N/A",
        Created_Date: item.date || "N/A",
        Status: item.status || "N/A",
        Requirement_Type: item.requirement_type || "N/A",
        Assigned_To: item.assigned_to || "N/A",
        Next_Follow_Date: item.next_follow_date || "N/A",
        Priority: item.priority || "N/A",
      }));

  useEffect(() => {
    fetchFilteredData(); // Initial data fetch
  }, []);

  const fetchFilteredData = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const params = {
        ...(fromFollowDate && { fromFollowDate }),
        ...(toFollowDate && { toFollowDate }),
      };
  
      const response = await axiosConfig.get("/enquiries", { params });
  
      console.log("API Response:", response.data); // Debugging API response
  
      const filteredData = response.data
        .filter((item) => {
          console.log(
            "Assigned to:", item.assigned_to, 
           "Logged-in User ID:", userId,
            "Role:", role, 
            "Comp Name:", compName,
            "Status:", item.status
          ); // Debugging
  
          if (role === "SalesManager") {
           
            return item.comp_name?.toLowerCase() === compName?.toLowerCase();
          } else if (role === "Admin") {
           
            return true;
          } else if(role ==="SalesEngineer"){
           
            return item.user_id == userId;
          }
        })
        .map((item, index) => ({
          id: index + 1,
          enquiryID: item.enquiry_id || "N/A",
          Company_Name: item.masterCompany?.company_name || "Unknown Company",
          Name: item.masterCompany?.contacts?.[0]?.name || "N/A",
          Mobile_No: item.masterCompany?.contacts?.[0]?.phone || "N/A",
          Email_Id: item.email || "N/A",
          ExpectedPrice: item.total
            ? `₹${Number(item.total).toLocaleString()}`
            : "N/A",
          Created_Date: item.date || "N/A",
          Status: item.status || "N/A",
          Requirement_Type: item.requirement_type || "N/A",
          Assigned_To: item.assigned_to || "N/A",
          Next_Follow_Date: item.next_follow_date || "N/A",
          Priority: item.priority || "N/A",
        }));
  
      if (filteredData.length > 0) {
        setProducts(filteredData);
        setNoDataMessage(""); // Clear no data message
      } else {
        setProducts([]);
        setNoDataMessage("No open follow-ups available.");
      }
  
      setFilteredProducts([]);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setProducts([]);
        setFilteredProducts([]);
      } else {
        console.error("Error fetching data:", error);
        setError("Unexpected error occurred while fetching data.");
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  
  // Display loading spinner
  if (loading) {
    return (
      <div className={styles["spinner-container"]}>
        <div className="loading-text">Loading...</div>
        <div className="spinner-border spinner-custom" role="status"></div>
      </div>
    );
  }

  // If there's an error, display the error message
  if (error) {
    return <div className="text-center text-danger mt-5">Error: {error}</div>;
  }

  const handleApplyFilters = async () => {
    closeModal();
    fetchFilteredData();
  };

  const handleResetFilters = () => {
    setFromDate("");
    setToDate("");
    fetchFilteredData();
  };

  const addNavigateButton = () => {
    localStorage.setItem("action", "add");
    navigate("/followup");
  };

  const handleEdit = (enquiryID) => {
    localStorage.setItem("action", "edit");
    localStorage.setItem("enquiryID", enquiryID);
    navigate("/followup", { state: { enquiryID } });
  };



  const columns = [
    {
      dataField: "id",
      text: "S.No",
      sort: true,
      headerClasses: styles["header-sn"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className={styles["icon-color"]} />}
          title="S.No"
          tooltipText="Serial Number"
        />
      ),
    },
    {
      dataField: "enquiryID",
      text: "Enquiry ID",
      sort: true,
      headerClasses: styles["header-enqid"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge className={styles["icon-color"]} />}
          title="Enquiry ID"
          tooltipText="Enquiry ID"
        />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" prefix to the enquiryID value
    },
    {
      dataField: "Company_Name",
      text: "Company Name",
      sort: true,
      headerClasses: styles["header-company"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness className={styles["icon-color"]} />}
          title="Company Name"
          tooltipText="Company Name"
        />
      ),
    },
    {
      dataField: "Name",
      text: "Contact Person",
      sort: true,
      headerClasses: styles["header-contact"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdPerson className={styles["icon-color"]} />}
          title="Contact"
          tooltipText="Contact Person"
        />
      ),
    },
    {
      dataField: "Mobile_No",
      text: "Mobile",
      sort: true,
      headerClasses: styles["header-mobile"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsTelephone className={styles["icon-color"]} />}
          title="Mobile_No"
          tooltipText="Mobile Number"
        />
      ),
    },

    {
      dataField: "ExpectedPrice",
      text: "Order Value",
      sort: true,
      headerClasses: styles["header-value"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsCurrencyRupee className={styles["icon-color"]} />}
          title="Order Value"
          tooltipText="Order Value"
        />
      ),
    },
    {
      dataField: "Priority",
      text: "Priority",
      sort: true,
      headerClasses: "follow-priority",
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsFillEnvelopeFill className={styles["icon-color"]} />}
          title="Priority"
          tooltipText="Priority"
        />
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      headerClasses: styles["header-status"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsFillEnvelopeFill className={styles["icon-color"]} />}
          title=" Status"
          tooltipText="Status"
        />
      ),
    },

    //   {
    //     dataField: 'Age',
    //     text: 'Age',
    //     sort: true,
    //     headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
    //     style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
    //     headerFormatter: () => (
    //       <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Age" tooltipText="Age" />
    //     ),
    //   },
    {
      dataField: "Next_Follow_Date",
      text: "Next_Follow_Date",
      sort: true,
      headerClasses: "follow-nextdate",
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className={styles["icon-color"]} />}
          title="Date"
          tooltipText="Next_Follow_Date"
        />
      ),
    },

    {
      dataField: "Assigned_To",
      text: "Assigned_To",
      sort: true,
      headerClasses: "follow-assignto",
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className={styles["icon-color"]} />}
          title="Assigned"
          tooltipText="Assigned_To"
        />
      ),
    },

    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: "follow-action",
      classes: "cell-followup",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className={styles["icon-color"]} />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => (
        <div className={styles["action-buttons"]}>
          <BsPencilFill
            className={styles["action-icon-edit"]}
            onClick={() => handleEdit(row.enquiryID)}
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div>
      <Header />
   
        <div>
        <Button
          variant="primary"
          className="close-filter-button"
          onClick={openModal}
        >
          <BsFilter className="filter-icon" />
          Filter
        </Button>
        {isModalOpen && (
          <Modal show onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label>From Date:</label>
                <input
                  type="date"
                  value={fromFollowDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="form-control mb-3"
                />
              </div>
              <div>
                <label>To Date:</label>
                <input
                  type="date"
                  value={toFollowDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="form-control mb-3"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleResetFilters}>
                Reset
              </Button>
              <Button variant="primary" onClick={handleApplyFilters}>
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
     

      <div className="follow-box">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={products}
          columns={columns}
          pagination={pagination}
          noDataIndication={() => "No data found"}
          striped
        />
      </div>

      {/* Error Popup */}
      <Modal show={showErrorPopup} onHide={handleClosePopup} centered>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClosePopup}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FollowUpManagement;
