import React, { useEffect, useState } from "react";
import Header from "../../home/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import "../../css/Quotationmanagement.css";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsFillFunnelFill,
  BsPencilFill,
  BsFillEnvelopeFill,
} from "react-icons/bs";
import { BsTelephone, BsCurrencyRupee, BsChevronDown } from "react-icons/bs";
import { TbVersions } from "react-icons/tb";
import { MdBusiness, MdPerson } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaIdBadge, FaCalendarAlt, FaTasks } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axiosConfig from "../../services/axiosConfig";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function QuotationManagement() {
  const navigate = useNavigate();
  const [quotations, setQuotations] = useState([]);

  const addNavigateButton = () => {
    localStorage.setItem("action", "add");
    navigate("/quotation");
  };

  const handleEdit = (quotationId) => {};
  const handleDelete = (quotationId) => {};

  const columns = [
    {
      dataField: "id",
      text: "S.No",
      sort: true,
      headerClasses: "quotation-sn",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className="icon-tip" />}
          title="S.No"
          tooltipText="Serial Number"
        />
      ),
    },
    {
      dataField: "quotation_id",
      text: "Quotation ID",
      sort: true,
      headerClasses: "quotation-id",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge className="icon-tip" />}
          title="Quotation ID"
          tooltipText="Enquiry ID"
        />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" prefix to the enquiryID value
    },
    {
      dataField: "Company_Name",
      text: "Company Name",
      sort: true,
      headerClasses: "quotation-name",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness className="icon-tip" />}
          title="Company Name"
          tooltipText="Company Name"
        />
      ),
    },
    {
      dataField: "Name",
      text: "Contact Person",
      sort: true,
      headerClasses: "quotation-contact",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdPerson className="icon-tip" />}
          title="Contact "
          tooltipText="Contact Person"
        />
      ),
    },
    {
      dataField: "Mobile_No",
      text: "Mobile",
      sort: true,
      headerClasses: "quotation-mobile",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsTelephone className="icon-tip" />}
          title="Mobile"
          tooltipText="Mobile Number"
        />
      ),
    },
    {
      dataField: "ExpectedPrice",
      text: "Order Value",
      sort: true,
      headerClasses: "quotation-value",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsCurrencyRupee className="icon-tip" />}
          title="Value"
          tooltipText="Order Value"
        />
      ),
    },
    {
      dataField: "Assigned_To",
      text: "Assigned_To",
      sort: true,
      headerClasses: "quotation-assigned",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className="icon-tip" />}
          title="Assigned"
          tooltipText="Assigned_To"
        />
      ),
    },
    {
      dataField: "version",
      text: "Version",
      sort: true,
      headerClasses: "quotation-version",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<TbVersions className="icon-tip" />}
          title="version"
          tooltipText="version"
        />
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      headerClasses: "quotation-status",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsFillEnvelopeFill className="icon-tip" />}
          title=" Status"
          tooltipText="Status"
        />
      ),
    },
    {
      dataField: "Created_Date",
      text: "Created Date",
      sort: true,
      headerClasses: "quotation-date",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className="icon-tip" />}
          title="Date"
          tooltipText="Created Date"
        />
      ),
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: "quotation-action",
      classes: "cell-quotation",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className="icon-tip" />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => (
        <div className="act">
          <BsTrash3Fill
            className="action-delete"
            onClick={() => handleDelete(row.enquiryID)}
          />
          <BsPencilFill
            className="action-edit"
            onClick={() => handleEdit(row.enquiryID)}
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  return (
    <div>
      <Header />
      <div className="quotation-box">
      <div className='quotation-buttons'> 
        <Button
          variant="primary"
          className="add-quota-button"
          onClick={addNavigateButton}
        >
          <BsPlusCircleFill className="add-quota-icon" />
          Create Quotation
        </Button>
        </div>
        {/* <Button variant="primary" style={{ marginLeft: '1000px', marginTop: '-63px', fontFamily: "'Manrope'", fontSize: '14px', background: '#7C3E66' }}>
                        <BsTrash3Fill style={{ marginRight: '5px', fontSize: '14px' }} />
                        Delete
                    </Button> */}
        {/* <Button variant="primary" style={{ marginRight: '1120px', marginTop: '-110px', fontFamily: "'Manrope'", fontSize: '14px', background: '#7C3E66' }}>
                        <BsFillFunnelFill style={{ marginRight: '5px', fontSize: '14px' }} />
                        Filter
                    </Button> */}
        <div className="App">
          <BootstrapTable
            bootstrap4
            keyField="quotation_id"
            data={quotations}
            columns={columns}
            striped
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
}

export default QuotationManagement;
