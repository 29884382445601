import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../../css/EnquiryManagement.module.css";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import "../../css/Closure.css";
import {
  BsChevronDown,
  BsCurrencyRupee,
  BsEyeFill,
  BsFilter,
} from "react-icons/bs";
import { MdOutlineSource } from "react-icons/md";
import { MdBusiness, MdPerson } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { CgNotes } from "react-icons/cg";
import { FaIdBadge, FaCalendarAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "axios";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axiosConfig from "../../services/axiosConfig";
import { Spinner } from "react-bootstrap";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function Closure() {
  const [enquires, setEnquires] = useState([]);
  const navigate = useNavigate();
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [notes, setNotes] = useState("");
  const maxCharacters = 300;
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [status, setStatus] = useState("");
  const [filterEnquiry, setFilterEnquiry] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(["Open"]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null); 
  const userId = localStorage.getItem("loggedInUserId"); 
  const [noDataMessage, setNoDataMessage] = useState(""); 
  const role = localStorage.getItem("role");
  const compName=localStorage.getItem("compName")

  const handleRowSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows([row.id]);
      setSelectedEnquiryId(row.enquiryID); 
    } else {
      setSelectedRows([]);
      setSelectedEnquiryId(null); 
    }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleView = (enquiryID) => {
    console.log("View details for enquiry ID:", enquiryID);
    navigate("/enquiry", { state: { enquiryID, action: "view" } });
  };

  const handleCloseModal = () => setShowCloseModal(!showCloseModal);

  const handleSave = () => {
    if (!selectedEnquiryId) {
      alert("Please select an enquiry first.");
      return;
    }
    const currentDate = new Date().toISOString().split("T")[0]; 
   
    const updatedEnquiry = {
      status: status,
      notes: notes,
      next_follow_date: currentDate, 
    };

    axiosConfig
      .put(`/enquiries/${selectedEnquiryId}`, updatedEnquiry)
      .then((response) => {
        console.log("Enquiry updated successfully:", response.data);
        handleCloseModal(); 
        window.location.reload(); 
      })
      .catch((error) => {
        console.error("Error updating enquiry:", error);
      });
  };
  useEffect(() => {
    fetchEnquiries("?status=Open");
  }, []);

  const fetchEnquiries = async (queryParams = "") => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await axiosConfig.get(`/enquiries${queryParams}`);
  
      console.log("API Response:", response.data); // Debugging API response
  
      const formattedData = response.data
        .filter((item) => {
          console.log(
            "Assigned to:", item.assigned_to,
            "Logged-in User ID:", userId,
            "Role:", role,
            "Comp Name:", compName
          ); // Debugging
  
          if (role === "SalesManager") {
           
            return item.comp_name?.toLowerCase() === compName?.toLowerCase();
          } else if (role === "Admin") {
           
            return true;
          } else if(role ==="SalesEngineer"){
           
            return item.user_id == userId;
          }
        })
        .map((item, index) => ({
          id: item.enquiry_id || index + 1,
          enquiryID: item.enquiry_id,
          companyName: item.masterCompany?.company_name || "N/A",
          contactPerson: item.contact || "No Contact Available",
          Next_Follow_Date: item.next_follow_date || "N/A",
          orderValue: item.products?.[0]?.order_value || "N/A",
          source: item.masterCompany?.address?.source || "N/A",
          assignedTo: item.authorizedUser?.user_name || "N/A",
          status: item.status || "N/A",
        }));
  
      // Handle no data
      if (formattedData.length > 0) {
        setEnquires(formattedData);
        setNoDataMessage(""); // Clear no data message
      } else {
        setEnquires([]);
        setNoDataMessage("No data available for the assigned user.");
      }
  
      setFilterEnquiry([]);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setEnquires([]);
        setFilterEnquiry([]);
      } else {
        console.error("Error fetching data:", error);
        setError("Unexpected error occurred while fetching data.");
      }
    } finally {
      // Simulate a delay for the loading spinner
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  
  

  if (loading) {
    return (
      <div className={styles["spinner-container"]}>
        <div className="loading-text">Loading...</div>
        <div className="spinner-border spinner-custom" role="status"></div>
      </div>
    );
  }
  // If there's an error, display the error message
  if (error) {
    return <div className="text-center text-danger mt-5">Error: {error}</div>;
  }

  const handleEdit = (enquiryID) => {
    localStorage.setItem("action", "edit");
    localStorage.setItem("enquiryID", enquiryID);
    navigate("/enquiry", { state: { enquiryID } });
  };

  const handleApplyFilters = () => {
    let queryParams = [];

    if (fromDate && toDate) {
      queryParams.push(`fromDate=${fromDate}&toDate=${toDate}`);
    }

    if (selectedStatus.length > 0) {
      selectedStatus.forEach((status) => {
        queryParams.push(`status=${status}`);
      });
    }

    const finalQueryParams = `?${queryParams.join("&")}`;
    fetchEnquiries(finalQueryParams);
    setIsModalOpen(false); // Close the modal after applying filters
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setSelectedStatus((prev) =>
      prev.includes(value)
        ? prev.filter((status) => status !== value)
        : [...prev, value]
    );
  };

  const handleResetFilters = () => {
    // Reset the filter values
    setFromDate("");
    setToDate("");
    setSelectedStatus(["Open"]);
    setFilterEnquiry([]);
    setIsModalOpen(false);
  };
  const columns = [
    {
      dataField: "enquiryID",
      text: "Enquiry ID",
      sort: true,
      headerClasses: "closure-sn",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge className="icon-white" />}
          title="Enquiry ID"
          tooltipText="Enquiry ID"
        />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" before the enquiryID value
    },
    {
      dataField: "Next_Follow_Date",
      text: "Next_Follow_Date",
      sort: true,
      headerClasses: "closure-nextfollow",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className="icon-white" />}
          title="Next Follow Date"
          tooltipText="Next_Follow_Date"
        />
      ),
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      headerClasses: "closure-companyname",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness className="icon-white" />}
          title="Company Name"
          tooltipText="Company Name"
        />
      ),
    },
    {
      dataField: "contactPerson",
      text: "Contact Person",
      sort: true,
      headerClasses: "closure-contact",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdPerson className="icon-white" />}
          title="Contact"
          tooltipText="Contact Person"
        />
      ),
    },
    {
      dataField: "orderValue",
      text: "Order Value",
      sort: true,
      headerClasses: "closure-order",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsCurrencyRupee className="icon-white" />}
          title="Order Value"
          tooltipText="Order Value"
        />
      ),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      headerClasses: "closure-source",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdOutlineSource className="icon-white" />}
          title="Source"
          tooltipText="Source"
        />
      ),
    },
    {
      dataField: "assignedTo",
      text: "Assigned To",
      sort: true,
      headerClasses: "closure-assign",
      classes: "cell-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className="icon-white" />}
          title="Assigned To"
          tooltipText="Assigned To"
        />
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerClasses: "closure-status",
      classes: "cell-center",
      formatter: (cell) => <span className="status-call">{cell || "N/A"}</span>,
    },
    {
      dataField: "View",
      text: "View",
      sort: false,
      headerClasses: "closure-view",
      classes: "cell-nowrap-center",
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<IoMdEye className="icon-white" />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      // formatter: (cell, row) => (
      //   <div className="act-buttons">
      //     <IoMdEye
      //       className="act-icon"
      //       onClick={() => handleDelete(row.enquiryID)}
      //     />
      //   </div>
      // ),
      formatter: (cell, row) => (
        <div>
          <BsEyeFill
            onClick={() => handleView(row.enquiryID)} // Add a handler for viewing
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };
  return (
    <div>
      <Header />
      <div className="closure-box">
        <div>
          <Button
            variant="primary"
            className="close-filter-button"
            onClick={openModal}
          >
            <BsFilter className="filter-icon" />
            Filter
          </Button>
          {isModalOpen && (
            <div className={styles["filter-overlay"]}>
              <div className={styles["filter-container"]}>
                <div className={styles["filter-header"]}>
                  <h2 className={styles["filter-titles"]}>Filter</h2>
                  <div className={styles["filter-close"]} onClick={closeModal}>
                    ×
                  </div>
                </div>
                <form>
                  <div className={styles["filter-group"]}>
                    <label className={styles["filter-label"]}>Date Range</label>
                    <div className={styles["date-range-container"]}>
                      <div className={styles["input-container"]}>
                        <label
                          htmlFor="fromDate"
                          className={styles["input-label"]}
                        >
                          From
                        </label>
                        <input
                          type="date"
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          className={styles["input-date"]}
                        />
                      </div>
                      <div className={styles["input-container"]}>
                        <label
                          htmlFor="toDate"
                          className={styles["input-label"]}
                        >
                          To
                        </label>
                        <input
                          type="date"
                          id="toDate"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          className={styles["input-date"]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles["filter-group"]}>
                    <label className={styles["filter-label"]}>Status</label>
                    <div className={styles["status-checkbox-container"]}>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Open"
                          checked={selectedStatus.includes("Open")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Open
                      </label>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Won"
                          checked={selectedStatus.includes("Won")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Won
                      </label>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Lost"
                          checked={selectedStatus.includes("Lost")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Lost
                      </label>
                    </div>
                  </div>

                  <div className={styles["modal-foot"]}>
                    <button
                      type="button"
                      onClick={handleApplyFilters}
                      className={styles["filter-bth"]}
                    >
                      Apply
                    </button>
                    <button
                      type="button"
                      onClick={handleResetFilters}
                      className={styles["filter-reset"]}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        <div>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={filterEnquiry.length > 0 ? filterEnquiry : enquires}
            columns={columns}
            striped
            pagination={pagination}
            noDataIndication={() => "No data found"}
            selectRow={{
              mode: "radio", // Can also use 'checkbox' for multiple rows
              clickToSelect: true,
              selected: selectedRows,
              onSelect: (row, isSelected) => handleRowSelect(row, isSelected),
            }}
          />

          <div className="closure-container">
            <Button
              variant="primary"
              onClick={handleCloseModal}
              className="closure-btn"
            >
              Close Enquiry
            </Button>

            <Modal
              show={showCloseModal}
              onHide={handleCloseModal}
              centered
              className="custom-modal"
            >
              <Modal.Header closeButton className="modal-head">
                <Modal.Title className="modal-title">Close Enquiry</Modal.Title>
              </Modal.Header>

              <Modal.Body className="modal-body">
                <Form>
                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">ENQ-ID</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        type="text"
                        value={selectedEnquiryId || "N/A"} // Display the selectedEnquiryId or "N/A"
                        readOnly
                        className="pop-up-control"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Date</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        type="date"
                        value={new Date().toISOString().split("T")[0]}
                        disabled
                        className="pop-up-control"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Closer</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="pop-up-control"
                      >
                        <option value="" disabled hidden>
                          Select status
                        </option>
                        <option>Won</option>
                        <option>Lost</option>
                      </Form.Control>
                    </div>
                  </Form.Group>

                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Notes</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter notes here..."
                        value={notes}
                        onChange={handleNotesChange}
                        maxLength={maxCharacters}
                        className="pop-up-control-text"
                      />
                      <div className="character-count">
                        {notes.length}/{maxCharacters} characters
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer className="modal-footer">
                <Button
                  variant="primary"
                  onClick={handleSave}
                  className="save-btn"
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Closure;
