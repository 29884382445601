// Header.js
import React from 'react';
import '../css/Header.css'


const Header = () => {
  return (
    <div className="Header">
      <div className="Header-content">
        <h2 className='Header-title ' >
        {
             window.location.pathname === "/dashboard" ? "Customer Information"
             :window.location.pathname === "/enquiry" ? "Enquiry Management"
             :window.location.pathname === "/followupManagement" ? "FollowUp Management"
             :window.location.pathname === "/followup" ? "Follow Up"
             :window.location.pathname === "/quotationManagement" ? "Quotation Management"
             :window.location.pathname === "/quotation" ? "Quotation Management"
             :window.location.pathname === "/closure" ? "Closure Management"
             :window.location.pathname === "/custom" ? "Customer Management"
             :window.location.pathname === "/enquire" ? "Enquiry Management"
             :window.location.pathname === "/enquiryManagement" ? "Enquiry Management"
             :window.location.pathname === "/quotations" ? "Quotation Management"
             :window.location.pathname === "/order" ? " Order Management"
             :window.location.pathname === "/orders" ? " Order Management"
             :window.location.pathname === "/pay" ? " Payment status tracking"
             :window.location.pathname === "/payment" ? " Payment status tracking "
             :window.location.pathname === "/CompanyManagement" ? "Company Management "
             :window.location.pathname === "/company" ? "Company Management"
             :window.location.pathname === "/ProductManagement" ? " Product Management "
             :window.location.pathname === "/Product" ? " Product Management "

             :''
        }
        </h2> 
      </div>
    </div>
  );
};


export default Header;
