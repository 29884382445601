import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../services/axiosConfig";
import Loginimage from "../Images/Loginimage.png";
import { logDOM } from "@testing-library/react";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    try {
      const token = response.credential;
      localStorage.setItem("authToken", token);
  
      // Step 1: Validate the user with the backend API
      const resp = await axiosConfig.get(`/validateUser`);
      console.log("respp", resp);
  
      // Store the user-related information in localStorage
      const { userId, fullName, userRole, compName } = resp.data;
      localStorage.setItem("fullName", fullName);
      localStorage.setItem("loggedInUserId", userId); // Store userId after login
      localStorage.setItem("role", userRole);
      localStorage.setItem("compName", compName);
  
      console.log("resp.data.role", compName);
  
      // Check if the token is valid
      if (resp.data.message === "Valid Token") {
        // Step 2: Only fetch authorized users if userId is stored in localStorage
        const userIdFromStorage = localStorage.getItem("loggedInUserId");
  
        if (userIdFromStorage) {
          // Now that userId is available, call /authorizedUsers/{userId}
          const usersResponse = await axiosConfig.get(`/authorizedUsers/${userIdFromStorage}`);
          console.log('userResponse',usersResponse);
          
          localStorage.setItem("authorizedUsers", JSON.stringify(usersResponse.data));
  
          // Fetch company data
          const companyResponse = await axiosConfig.get("/companies");
          const sortedCompanies = companyResponse.data.sort((a, b) =>
            a.company_name.localeCompare(b.company_name)
          ); // Sort alphabetically
          localStorage.setItem("companies", JSON.stringify(sortedCompanies));
  
          // Fetch product data
          const productsResponse = await axiosConfig.get("/products");
          localStorage.setItem("products", JSON.stringify(productsResponse.data)); // Save products data to localStorage
  
          // Navigate to the next page
          navigate("/followupManagement");
        } else {
          console.error("UserId is missing from localStorage");
          // Handle this error if necessary
        }
      } else if (resp.data.message === "InValid Token") {
        navigate("/UnauthorizedUser");
      }
    } catch (error) {
      console.error("Error during login or fetching companies:", error);
    }
  };
  
  

  const handleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        {/* Left side: Logo/Image */}
        <div style={styles.leftContainer}>
          <img src={Loginimage} alt="Login" style={styles.image} />
        </div>

        {/* Right side: Header and Google Login */}
        <div style={styles.rightContainer}>
          <p style={styles.subHeader}>Continue with your Google account</p>
          <GoogleOAuthProvider clientId={googleClientId}>

            <div style={styles.googleLoginContainer}>
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            </div>
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

// Updated styles for the component
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw", // Full width of the viewport
    backgroundColor: "#f7f7f7",
    padding: "0", // Ensure no padding on the container
    margin: "0", // Ensure no margin on the container
  },
  box: {
    display: "flex",
    width: "100%",
    maxWidth: "700px",
    height: "400px", // Increased height
    border: `2px solid #7c3e66`,
    borderRadius: "8px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  leftContainer: {
    width: "50%",
    backgroundColor: "#f0f0f5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    margin: "0", // Remove any automatic margin
    padding: "0", // Remove any automatic padding
  },
  image: {
    width: "100%",
    height: "450px",
    objectFit: "cover",
  },
  rightContainer: {
    width: "40%", // Adjust width for remaining space
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#7C3E66",
    textAlign: "center",
    marginBottom: "10px",
  },
  subHeader: {
    fontSize: "16px",
    color: "#555",
    textAlign: "center",
    marginBottom: "20px",
  },
  googleLoginContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

export default Login;
