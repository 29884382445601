import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router';
import React, { useState, useEffect } from 'react';
import Login from './login/Login';
import UnauthorizedUser from './login/UnAuthorizedUser';
import Sidebar from './home/Dashboard';
import EnquiryManagement from './sideBar/enquiry/EnquiryManagement';
import Enquiry from './sideBar/enquiry/Enquiry';
import FollowUpManagement from './sideBar/followUp/FollowUpManagement';
import FollowUp from './sideBar/followUp/FollowUp';
import Closure from './sideBar/closure/Closure';
import CompanyManagement from './sideBar/company/CompanyManagement';
import Company from './sideBar/company/Company'
import ProductManagement from './sideBar/product/ProductManagement';
import Product from './sideBar/product/Product'
import QuotationManagement from './sideBar/quotation/QuotationManagement';
import Quotation from './sideBar/quotation/Quotation';


const SidebarLayout = () => {
  const [loading, setLoading] = useState(false);

  // Handle loading when navigating
  const delayNavigation = (delay) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false); // Hide the loading symbol after delay
    }, delay);
  };

  return (
    <div style={{ display: 'flex', maxWidth: '100%', paddingRight: '20px', marginLeft: '260px', overflowX: 'hidden' }}>
      <Sidebar />
      {loading ? (
        <div className="loading-spinner">
          {/* A simple loading spinner */}
          <div className="spinner">Loading...</div>
        </div>
      ) : (
        <Outlet context={{ delayNavigation }} />
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/UnauthorizedUser" element={<UnauthorizedUser />} />
        <Route path="/dashboard" element={<Sidebar />} />
        
        <Route element={<SidebarLayout />}>
          <Route path="/enquiryManagement" element={<EnquiryManagement />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/followupManagement" element={<FollowUpManagement />} />
          <Route path="/followup" element={<FollowUp />} />
          <Route path="/closure" element={<Closure />} />
          <Route path="/companyManagement" element={<CompanyManagement />} />
          <Route path="/company" element={<Company/>} />
          <Route path="/ProductManagement" element={<ProductManagement />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/quotationManagement" element={<QuotationManagement />} />
          <Route path="/quotation" element={<Quotation />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
