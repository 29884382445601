import React, { useState } from "react";
import Header from "../../home/Header";
import "../../css/Quotation.css";
import styles from "../../css/Enquiry.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsFillFunnelFill,
  BsPencilFill,
} from "react-icons/bs";
import axiosConfig from "../../services/axiosConfig";

function Quotation() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaxOpen, setIsTaxOpen] = useState(false);
  const [isSGSTOpen, setIsSGSTOpen] = useState(false);
  const [isIGSTOpen, setIsIGSTOpen] = useState(false);

  const [isSGSTTable, setIsSGSTTable] = useState(false);
  const [isIGSTTable, setIsIGSTTable] = useState(false);

  const [Product, setProduct] = useState("");
  const [EnquiryID, setEnquiryID] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [Discount, setDiscount] = useState("");
  const [Companyname, setCompanyname] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [shipDoorNo, setShipDoorNo] = useState("");
  const [shipstreetname, setShipStreetname] = useState("");
  const [shipDistrict, setShipDistrict] = useState("");
  const [shipAreaTerritory, setShipAreaTerritory] = useState("");
  const [shipstate, setShipState] = useState("");
  const [shippincode, setShipPincode] = useState("");
  const [users, setusers] = useState("");
  const [SalesRepresentative, setSalesRepresentative] = useState("");
  const [FollowupNotes, setFollowupNotes] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState("");
  const [type, settype] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [RelatedEnquiryLead, setRelatedEnquiryLead] = useState("");
  const [types, settypes] = useState(0);
  const [paymentDueDate, setPaymentDueDate] = useState(null);
  const [QuotationID, setQuotationID] = useState(null);
  const [createFileSave, setcreatefileSave] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const [tableData, setTableData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [compAddrId, setCompAddrId] = useState(0);
  const [inputdata, setInputdata] = useState({
    quantity: "",
  });

  const isDataValid = SalesRepresentative.trim() !== "";

  const arr = ["pending", "accepted", "rejected"];

  const handleProductChange = (e) => {
    e.preventDefault();
    // console.log("e.target.value", e.target.value);
    setSelectedProduct(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddToTable = (e) => {
    e.preventDefault();
    if (selectedProduct && quantity) {
      const newItem = { label: selectedProduct, quantity, productId: 0 };
      setTableData([...tableData, newItem]);
      setSelectedProduct("");
      setQuantity("");
    }
  };

  const handleChangeUser = (event) => {
    setusers(event.target.value);
  };

  const handleChange = (event) => {
    settype(event.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("handleFileChange:", file);
    setSelectedFile(file);
  };

  const handleChangetypes = (event) => {
    // const selectedValue = parseInt(event.target.value, 10);
    // const matchedEnquiry = parsedEnqDetails.find(detail => detail.enquiry_id === selectedValue);
    // setCompanyId(matchedEnquiry.company_id);
    // setCompAddrId(matchedEnquiry.company_address_id);
    // setEnquiryID(selectedValue);
    // settypes(selectedValue);
    // getCompanyName(selectedValue);
  };

  const navigate = useNavigate();
  const navigateButton = () => {
    navigate("/quotation");
  };

  // console.log('typestypes:',types);

  //   async function quotation() {
  //     console.log("quat");

  //     try{
  //     const quotationObj = {
  //       quotation_id: QuotationID,
  //       enquiry_id: EnquiryID,
  //       company_id: companyId,
  //       company_address_id: compAddrId,
  //       quotation_status: users,
  //       sales_representative: SalesRepresentative,
  //       next_follow_up_action: NextFollowupAction,
  //       follow_up_outcome: type,
  //       follow_up_notes: FollowupNotes,
  //       quotation_bill: selectedFile.name,
  //       lead: RelatedEnquiryLead,
  //       payment_option: "card",
  //       discount: Discount,
  //     };
  //     console.log("quotationObj", quotationObj);
  //     await axiosConfig
  //       .post(`/add/quotations`, quotationObj)
  //       .then((response) => {
  //         let data_Quotation_id = response.data.quotation_id;
  //         console.log("data_Quotation_id :" + data_Quotation_id);
  //         localStorage.setItem("getQuotation_id", JSON.stringify(data_Quotation_id));
  //       });
  //     }
  //     catch(e){
  //       console.log('quotation save exception :',e);
  //     }
  //   }

  //   async function product() {

  //     try{
  //     let productDetails = [];

  //     tableData.forEach((element) => {
  //       const productobj = {
  //         product_customer_id: 1,
  //         product_name: element.label,
  //         product_image_location: "http:pic-01",
  //         list_price: TotalAmount,
  //       };
  //       productDetails.push(productobj);
  //     });

  //     await axiosConfig.post(`/add/products`, productDetails)
  //     .then((response) => {
  //       let productResp = response.data
  //       let localArr = []

  //       const minLength = Math.min(tableData.length, productResp.length);
  //       for (let i = 0; i < minLength; i++) {
  //         const ele = tableData[i];
  //         const object = productResp[i];

  //         let prodObj = {
  //           prodId: object.product_id,
  //           label: ele.label,
  //           quantity: ele.quantity,
  //         };

  //         localArr.push(prodObj);
  //       }
  //       localStorage.setItem("ProductIDs",JSON.stringify(localArr));
  //     });
  //   }
  //   catch(e){
  //      console.log('product save exception :',e);
  //   }
  //   }

  //   async function quotationProduct() {
  //     try{
  //     let quotationDetails = [];

  //     let getProdDatas = localStorage.getItem("ProductIDs");
  //     let parsedProdDatas = JSON.parse(getProdDatas);

  //     const quotationId = localStorage.getItem("getQuotation_id");
  //     const parsedQuotationId = parseInt(JSON.parse(quotationId));
  //     console.log("parsedQuotationId:",parsedQuotationId);

  //     parsedProdDatas.forEach((prod) =>{
  //       const quotationProductobj = {
  //         quotationId: parsedQuotationId,
  //         product_id: prod.prodId,
  //         product_qty: prod.quantity,
  //       };
  //       quotationDetails.push(quotationProductobj);

  //     });

  //     console.log("quotationDetails:",quotationDetails);

  //     await axiosConfig.post(
  //       `/add/quotation_products`,
  //       quotationDetails
  //     );
  //   }
  //   catch(e){
  //     console.log('quotation product exception :',e);
  //   }
  //   }

  //   const saveDatas = async () => {
  //     await quotation();
  //     await product();
  //     await quotationProduct();
  //   };

  const clearDatas = async () => {
    setDoorNo("");
    setstreetname("");
    setDistrict("");
    setAreaTerritory("");
    setState("");
    setPincode("");
    setShipDoorNo("");
    setShipStreetname("");
    setShipDistrict("");
    setShipAreaTerritory("");
    setShipState("");
    setShipPincode("");
    setCompanyName("");
    // setSelectedProduct("")
    // setQuantity("")
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };
  const openTax = () => {
    console.log("Modal is opening...");
    setIsTaxOpen(true); // This will trigger the modal to appear
  };
  const openSGST = () => {
    console.log("Modal is opening...");
    setIsSGSTOpen(true); // This will trigger the modal to appear
  };
  const openIGST = () => {
    console.log("Modal is opening...");
    setIsIGSTOpen(true); // This will trigger the modal to appear
  };

  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };
  const closeTax = () => {
    console.log("Modal is closing...");
    setIsTaxOpen(false); // This will close the modal
  };
  const closeSGST = () => {
    console.log("Modal is closing...");
    setIsSGSTOpen(false); // This will close the modal
  };
  const closeIGST = () => {
    console.log("Modal is closing...");
    setIsIGSTOpen(false); // This will close the modal
  };
  const submitSGST = () => {
    console.log("Modal is closing...");
    setIsSGSTOpen(false);
    setIsTaxOpen(false);
    setIsSGSTTable(true);
  };
  const submitIGST = () => {
    console.log("Modal is closing...");
    setIsIGSTOpen(false);
    setIsTaxOpen(false);
    setIsIGSTTable(true);
  };
  return (
    <div>
      <Header />
      <form>
      <h2 className={styles['basic-info-title']}>Basic Information</h2>
        <div className={styles['basic-info-container']}>
          {/* Row 1 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Quotation ID</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Link Enquiry<span className="required">*</span>
            </label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Company Name</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>

          {/* Row 2 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Date</label>
            <input type="text" required className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Contact Person</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Mobile</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>

          {/* Row 3 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Email</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Designation</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Contact Person</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>

          {/* Row 4 - Pincode */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Mobile</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Email</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Designation</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Sales Person</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Mobile</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Email</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Designation</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Address</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              GSTIN<span className="required">*</span>
            </label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
        </div>
      </form>
      <form>
        <h2 className={styles['basic-info-title']}>Product Price</h2>
        <div className={styles['product-container']}>
          <div className={styles['table-container']}>
            <table className={styles['product-table']}>
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product Name</th>
                  <th>HSN No</th>
                  <th>Qty</th>
                  <th>Unit Rate</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {/* <tbody>
                  <tr >
                    <td>
                      <BsTrash3Fill
                        className="trash-icon"
                      />
                      <BsPencilFill
                        className="edit-icon"
                       
                      />
                    </td>
                  </tr>
                 </tbody> */}
            </table>
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Discount</label>
            <input type="text" className={styles['basic-info-input']}  placeholder="If Any" />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Total Amount</label>
            <input
              type="text"
              className={styles['basic-info-input']} 
              placeholder="Amount + Tax-Discount"
            />
          </div>
          <button type="button" className="add-quota-btn" onClick={openModal}>
            Add Product
          </button>
          {isModalOpen && (
            <div className="quota-overlay">
              <div className="modelopen-container">
                <div className="quota-header">
                  <h2 className="quota-titles">Add Product</h2>
                  <div className="modal-close" onClick={closeModal}>
                    ×
                  </div>
                </div>
                <form>
                  <div className="model-group">
                    <label className="model-label">Product Name</label>
                    <input type="text" className="input-model" />
                    {/* {isDropdownOpen && (
                      <div className="dropdown-list-1">
                        {products.map((product) => (
                          <div
                            key={product.id}
                            onClick={() => handleProductChange(product)}
                            className="dropdown-item"
                          >
                            {product.product_name}
                          </div>
                        ))}
                      </div>
                    )} */}
                  </div>

                  <div className="model-group">
                    <label className="model-label">Product Description</label>
                    <textarea name="description" className="area-field" />
                  </div>

                  <div className="model-group">
                    <label className="model-label">
                      HSN No <span className="required">*</span>
                    </label>
                    <input type="text" name="qty" className="input-model" />
                  </div>

                  <div className="model-group">
                    <label className="model-label">
                      Quantity <span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      name="orderValue"
                      className="input-model"
                    />
                  </div>

                  <div className="model-group">
                    <label className="model-label">
                      Unit Rate <span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      name="orderValue"
                      className="input-model"
                    />
                  </div>
                  <div className="model-group">
                    <label className="model-label">Amount</label>
                    <input
                      type="number"
                      name="orderValue"
                      className="input-model"
                    />
                  </div>
                  <div className="model-group">
                    <label className="model-label">
                      Lead Time<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="orderValue"
                      className="input-model"
                    />
                  </div>
                  <div className="model-group">
                    <label className="model-label">Notes</label>
                    <textarea name="description" className="area-field" />
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="submit-button">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </form>

      <form>
        <h2 className={styles['basic-info-title']}>Tax</h2>
        <div className={styles['product-container']}>
         

          {isSGSTTable && (
            <div className={styles['table-container']}>
              <table className={styles['product-table']}>
                <thead>
                  <tr>
                    <th>HSN</th>
                    <th>Taxable Amount</th>
                    <th colSpan="2">CGST (Rate & Amount)</th>
                    <th colSpan="2">SGST/UTGST</th>
                    <th>Total Tax Amount</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {isIGSTTable && (
            <div className={styles['table-container']}>
              <table className={styles['product-table']}>
                <thead>
                  <tr>
                    <th>HSN/SAC</th>
                    <th>Taxable Amount</th>
                    <th colSpan="2">CGST</th>
                    <th>Total Tax Amount</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <button type="button" className="add-quota-btn" onClick={openTax}>
            Add Tax
          </button>
          {isTaxOpen && (
            <div className="quotax-overlay">
              <div className="modeltax-container">
                <div className="quotax-header">
                  <h2 className="quota-titles">Add Tax</h2>
                  <div className="modal-tax" onClick={closeTax}>
                    ×
                  </div>
                </div>
                <div className="SGST-buttons">
                  <Button
                    variant="primary"
                    className="SGST-primary"
                    onClick={openSGST}
                  >
                    SGST
                  </Button>
                  {isSGSTOpen && (
                    <div className="quota-overlay">
                      <div className="modelopen-container">
                        <div className="quota-header">
                          <h2 className="quota-titles">Add SGST</h2>
                          <div className="modal-close" onClick={closeSGST}>
                            ×
                          </div>
                        </div>
                        <form>
                          <div className="model-group">
                            <label className="model-label">
                              Select HSN No <span className="required">*</span>
                            </label>
                            <input type="text" className="input-model" />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              Taxable Amount<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="qty"
                              className="input-model"
                            />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              CGST Rate <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="qty"
                              className="input-model"
                            />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              CGST Amount <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="orderValue"
                              className="input-model"
                            />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              SGST Rate <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="orderValue"
                              className="input-model"
                            />
                          </div>
                          <div className="model-group">
                            <label className="model-label">
                              SGST Amount <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="orderValue"
                              className="input-model"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="submit"
                              className="submit-button"
                              onClick={submitSGST}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
                <div className="IGST-buttons">
                  <Button
                    variant="primary"
                    className="IGST-primary"
                    onClick={openIGST}
                  >
                    IGST
                  </Button>
                  {isIGSTOpen && (
                    <div className="quota-overlay">
                      <div className="modelopen-container">
                        <div className="quota-header">
                          <h2 className="quota-titles">Add IGST</h2>
                          <div className="modal-close" onClick={closeIGST}>
                            ×
                          </div>
                        </div>
                        <form>
                          <div className="model-group">
                            <label className="model-label">
                              Select HSN No <span className="required">*</span>
                            </label>
                            <input type="text" className="input-model" />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              Taxable Amount <span className="required">*</span>
                            </label>
                            <input type="text" className="input-model" />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              IGST Rate <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="qty"
                              className="input-model"
                            />
                          </div>

                          <div className="model-group">
                            <label className="model-label">
                              IGST Amount <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              name="qty"
                              className="input-model"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="submit"
                              className="submit-button"
                              onClick={submitIGST}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
      <form>
        <h2 className={styles['basic-info-title']}>Product Price Details</h2>

        <div className={styles['basic-info-container']}>
          {/* Row 1 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Freight<span className="required">*</span>
            </label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Payment Terms<span className="required">*</span>
            </label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Offer Valid Till</label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>

          {/* Row 2 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Expected Delivery <span className="required">*</span>
            </label>
            <input type="text" required className={styles['basic-info-input']}  />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Account Details <span className="required">*</span>
            </label>
            <textarea name="note" className="area-field" />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              GSTIN <span className="required">*</span>
            </label>
            <input type="text" className={styles['basic-info-input']}  />
          </div>

          {/* Row 3 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Notes <span className="required">*</span>
            </label>
            <textarea name="note" className={styles['enquiry-area']} />
          </div>
        </div>
      </form>
      <div className={styles['enquiry-buttons']}>
        <Button variant="primary" className={styles['enquiry-primary']}>
          Submit
        </Button>
      </div>
      <div className={styles['reset-but']}>
        <Button variant="primary"  className={styles['enquiry-reset-btn']}>
          Reset
        </Button>
      </div>
    </div>
  );
}
export default Quotation;
