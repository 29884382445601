import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTag, FaBarcode } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsChevronDown,
} from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import company from "../../css/CompanyManagement.module.css";
import styles from "../../css/EnquiryManagement.module.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useNavigate } from "react-router";
import Header from "../../home/Header";
import axiosConfig from "../../services/axiosConfig";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();

  const getProducts = async () => {
    setLoading(true); // Set loading to true before API call
    setError(null); // Reset error state before API call
    try {
      const response = await axiosConfig.get("/products");
      const allProducts = response.data;

      const product = allProducts.map((item, index) => ({
        serialNumber: index + 1,
        productId: item.product_id,
        productName: item.product_name || "N/A",
        type: item.type || "N/A",
        partNo: item.part_no || "N/A",
        hsnNo: item.hsn_no || "N/A",
        unit: item.unit || "N/A",
      }));

      setProducts(product);
      localStorage.setItem("products", JSON.stringify(allProducts));
    } catch (error) {
      setError("Error fetching data: " + error.message); // Set error state
      console.error("Error fetching data:", error);
    } finally {
      // Simulate a delay for the loading spinner
      setTimeout(() => {
        setLoading(false); 
      }, 500); // Set loading to false after API call is finished
    }
  };
  

  const handleAddProduct = () => {
    navigate("/Product", { state: { mode: "create" } });
  };

  const handleEditProduct = (id) => {
    navigate("/Product", { state: { productId: id, mode: "edit" } });
  };

  useEffect(() => {
    getProducts();
  }, []);

   if (loading) {
    return (
      <div className={styles['spinner-container']}>
        <div className="loading-text">Loading...</div>
        <div className="spinner-border spinner-custom" role="status">
        
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger mt-5">Error: {error}</div>;
  }

  // Table Columns
  const columns = [
    {
      dataField: "serialNumber",
      text: "S.No",
      sort: true,
      headerClasses: styles["header-sn"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className={styles["icon-color"]} />}
          title="S.No"
          tooltipText="Serial Number"
        />
      ),
    },
    {
      dataField: "productName",
      text: "Product Name",
      sort: true,
      headerClasses: styles["header-company"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTag className={styles["icon-color"]} />}
          title="Product Name"
          tooltipText="Name of the Product"
        />
      ),
    },
    {
      dataField: "type",
      text: "Type (If any)",
      sort: true,
      headerClasses: styles["header-email"],
      classes: styles["cell-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTag className={styles["icon-color"]} />}
          title="Type"
          tooltipText="Product Type (If applicable)"
        />
      ),
    },
    {
      dataField: "partNo",
      text: "Part No. (If any)",
      sort: true,
      headerClasses: styles["header-contact"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaBarcode className={styles["icon-color"]} />}
          title="Part No."
          tooltipText="Part Number (If applicable)"
        />
      ),
    },
    {
      dataField: "hsnNo",
      text: "HSN No. (If any)",
      sort: true,
      headerClasses: styles["header-company"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaBarcode className={styles["icon-color"]} />}
          title="HSN No."
          tooltipText="HSN Code (If applicable)"
        />
      ),
    },
    {
      dataField: "unit",
      text: "Unit (If any)",
      sort: true,
      headerClasses: styles["header-email"],
      classes: styles["cell-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTag className={styles["icon-color"]} />}
          title="Unit"
          tooltipText="Product Unit (If applicable)"
        />
      ),
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: styles["header-action"],
      classes: styles["cell-nowrap-center"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTasks className={styles["icon-color"]} />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => {
        const productId = row.productId || row.id;

        return (
          <div className={styles["action-buttons"]}>
           <BsPencilFill
              className={styles["action-icon-edit"]}
              onClick={() => handleEditProduct(productId)}
            />
             {/* <BsTrash3Fill
              className={styles["action-icon-delete"]}
              onClick={() => handleDeleteProduct(productId)}
            /> */}
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    paginationClassName: "custom-pagination-container",
  });

  return (
    <div>
      <Header />
      <div className={company["company-box"]}>
        <div className={company["company-contain"]}>
          <Button
            variant="primary"
            onClick={handleAddProduct}
            className={company["add-company-button"]}
          >
            <BsPlusCircleFill className={company["add-company-button-icon"]} />
            Add New Product
          </Button>
        </div>
      </div>
      <div className={company["company-table"]}>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={products}
          columns={columns}
          pagination={pagination}
          striped
        />
      </div>
    </div>
  );
}

export default ProductManagement;
